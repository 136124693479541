import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'

const apiUrl = process.env.VUE_APP_API_URL

const apiClient = axios.create({
  baseURL: apiUrl,
  // timeout: 1000,
  // headers: { 'Access-Control-Allow-Origin': '*' },
  withCredentials: false,
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  const expiresAt = store.get('expiresAt')
  const lastServerTime = store.get('lastServerTime')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
    if (expiresAt) {
      if (lastServerTime && accessToken) {
        const remTime = expiresAt - lastServerTime
        if (remTime < 60) {
          console.log('auth expired')
          store.remove('expiresAt')
          store.remove('accessToken')
          location.reload()
        }
      }
    }
  } else {
    console.log('no access token')
    if (lastServerTime) {
      // console.log('lastServerTime', lastServerTime)
    }
    // const name = 'accessToken'
    // const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    // if (match) {
    //   const sessionToken = match[2]
    //   request.headers.Authorization = `Bearer ${sessionToken}`
    // }
    console.log('expired at', expiresAt)
  }
  return request
})

apiClient.interceptors.response.use(response => {
  const serverTime = response.headers.date
  const expiresAt = store.get('expiresAt')
  const lastServerTime = store.get('lastServerTime')
  if (serverTime) {
    store.set('lastServerTime', Date.parse(serverTime) / 1000)
  }
  if (response.data.error) {
    // Error message is retrived from the JSON body.
    const error = new Error(response.data.error.message)
    // Attach the response instance, in case we would like to access it.
    error.response = response
    if (typeof (response.data.noauth) !== 'undefined') {
      console.log('redirect to LOGOUT')
      if (expiresAt && lastServerTime) {
        // const remTime = expiresAt - lastServerTime
        // console.log('session remaining time', remTime)
      }
      const accessToken = store.get('accessToken')
      if (accessToken) {
        console.log('removing access token')
        store.remove('accessToken')
        location.reload()
      }
    }
    throw error
  }
  // If the status is true, simply return back the response instance.
  return response
}, error => {
  // Errors handling
  console.log(error)
  const { response } = error
  const { data } = response
  console.log(response)
  if (data) { console.log(data) }
  const serverTime = response.headers.date
  const expiresAt = store.get('expiresAt')
  const lastServerTime = store.get('lastServerTime')
  if (serverTime) {
    store.set('lastServerTime', Date.parse(serverTime) / 1000)
  }
  if (data && data.error && (!data.error.message)) {
    notification.warning({
      message: data.error,
    })
  }

  if (typeof (data.noauth) !== 'undefined') {
    console.log('redirect to LOGOUT')
    if (expiresAt && lastServerTime) {
      // const remTime = expiresAt - lastServerTime
      // console.log('session remaining time', remTime)
    }
    const accessToken = store.get('accessToken')
    if (accessToken) {
      console.log('removing access token')
      store.remove('accessToken')
      location.reload()
    }
  }
  return Promise.reject(data.error)
})

export default apiClient
