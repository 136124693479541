import localeAntd from 'ant-design-vue/lib/locale-provider/ru_RU'

const messages = {
  topBar: {
    issuesHistory: 'История заданий',
    projectManagement: 'Управление проектом',
    typeToSearch: 'Поиск...',
    findPages: 'Поиск страниц...',
    actions: 'Действия',
    status: 'Статус',
    profileMenu: {
      hello: 'Привет',
      billingPlan: 'Тарифный план',
      role: 'Роль',
      email: 'Email',
      phone: 'Телефон',
      editProfile: 'Редактировать профиль',
      logout: 'Выйти',
    },
    panelManagement: {
      title: 'Управление',
      main: 'Основное',
      users: 'Пользователи',
      others: 'Другое',
      roles: 'Права и роли',
      promocodes: 'Промокоды',
      analytics: 'Аналитика',
      binancio: 'Binancio',
      settings: 'Настройки',
      psystems: 'Платежные системы',
      currencies: 'Валюты и курсы',
      projects: 'Проекты',
    },
  },
}

export default {
  locale: 'ru-RU',
  localeAntd,
  messages,
}
