import { getField, updateField } from 'vuex-map-fields'

const state = {
  ps: {},
  psArray: [],
  sortUpdate: false,
}

const getters = {
  getField,
}
const mutations = {
  updateField,
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
