<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <router-link to="/">Главная</router-link>
      <template v-for="(item, index) in breadcrumb">
        <span v-if="index != 0" :key="index">
          <span :class="$style.arrow"></span>
          <router-link
            tag="a"
            :to="{name: item.key, params: { }}"
          >
            <span><a href="javascript;"> {{item.title}}</a></span>
          </router-link>
        </span>
      </template>
      <span v-if="activeItem">
        <span :class="$style.arrow"></span>
        <span :class="$style.current">{{activeItem.title}}</span>
      </span>
    </div>
<!--    <h3>{{ $route.meta.title }}</h3>-->
  </div>
<!--  <div>-->
<!--    <a-breadcrumb :routes="$route.matched">-->
<!--      <template slot="itemRender" slot-scope="{ route, params, paths }">-->
<!--        <span v-if="$route.matched.indexOf(route) === $route.matched.length - 1">-->
<!--          {{ route.name }}1-->
<!--        </span>-->
<!--        <router-link v-else :to="`/${paths.join('/')}`">-->
<!--          {{ route.name }}1-->
<!--        </router-link>-->
<!--      </template>-->
<!--    </a-breadcrumb>-->
<!--    <br />-->
<!--    {{ $route.path }}-->
<!--  </div>-->
</template>

<script>
import { mapState } from 'vuex'
import { getMenuData } from '@/services/menu'
import reduce from 'lodash/reduce'

export default {
  name: 'breadcrumbs',
  data() {
    return {
      breadcrumb: [],
      activeItem: {},
      path: [],
      // routes: this.$route.matched,
    }
  },
  computed: {
    ...mapState(['settings']),
    menuData() {
      return getMenuData
    },
  },
  methods: {
    getPath(data, url, parents = []) {
      if (url === '/') {
        url = '' // '/dashboard/alpha'
      }
      const items = reduce(
        data,
        (result, entry) => {
          if (result.length) {
            return result
          }
          if (entry.children) {
            const nested = this.getPath(entry.children, url, [entry].concat(parents))
            return (result || []).concat(nested.filter(e => !!e))
          }
          // url = url.replace(/list.*$/i, "")
          if (url.includes('/list/')) {
            url = url.substring(0, url.indexOf('/list/') + '/list/'.length)
          }
          if (entry.url === url) {
            return [entry].concat(parents)
          }
          return result
        },
        [],
      )
      this.activeItem = items[0]
      return items
    },
  },
  mounted: function () {
    this.breadcrumb = this.getPath(this.menuData, this.$route.path)
    // console.log(this.routes)
  },
  watch: {
    $route(to) {
      this.breadcrumb = this.getPath(this.menuData, to.path)
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
